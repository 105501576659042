import * as React from 'react';

const separatorStyles = {
  /*height: '4vh',
  width: 10,
  borderLeft: '1px solid #E1E1E1',
  borderRight: '1px solid #E1E1E1',
  background: 'white',
  zIndex: 10,
  left: '-1px',*/
};

export interface ISeparator {
  height?: string | number;
}

export const Separator = ({height}: ISeparator): JSX.Element => {
  return <div className="stepperSeparator" style={{...separatorStyles}} />;
};
