// @ts-ignore
import React from "react";
import { Step } from './Step';
import { Separator } from './Separator';

export interface IStepDescription {
  stepContent: () => JSX.Element;
  stepStateColor?: string;
  stepStatusCircleSize?: number;
  onClickHandler?: () => void | undefined;
}

export interface IStepperNavProps {
  steps: IStepDescription[];
}

export const StepperNav = (props: IStepperNavProps): JSX.Element => {
  return (
    <nav className="nav-op">
      {props.steps.map(
        (
          { stepContent, stepStateColor, onClickHandler, stepStatusCircleSize },
          index,
        ) => (
          <div className="opt" data-index-number={index} key={index}>
            <Step
              stepContent={stepContent}
              statusColor={stepStateColor}
              onClickHandler={onClickHandler}
              statusCircleSize={stepStatusCircleSize}
            />
            {index !== props.steps.length - 1 && (
              <div className="separatorBotton">
                <Separator />
              </div>
            )}
          </div>
        ),
      )}
    </nav>
  );
};
