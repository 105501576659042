import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./pie_chart.css";

am4core.useTheme(am4themes_animated);

function PieChart({ dataSet, resultText, resultTitle }) {
  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.PieChart);
      // Disable amCharts Logo
      if(chart.logo) {
          chart.logo.disabled = true;
      }

    chart.data = dataSet;

    let colorSet = new am4core.ColorSet();
    colorSet.list = [

     "#ccedfa",
     "#00A3E4",
     "#004693",
     "#66c8ef",
     "#4779af",
     "#0D2055",
     "#99daf4",
     "#33b5e9"].map(function(color) {
      return new am4core.color(color);
    });


    let pieSeries = chart.series.push(new am4charts.PieSeries());

    pieSeries.colors = colorSet;
    pieSeries.dataFields.value = "porcentaje";
    pieSeries.dataFields.category = "instrumento";

    // Let's cut a hole in our Pie chart the size of 60% the radius
    chart.innerRadius = am4core.percent(55);

    // Disable ticks and labels
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Disable tooltips
    pieSeries.slices.template.tooltipText = "{instrumento}";

    // Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.truncate = true;
    chart.legend.position = "right";
    chart.legend.maxWidth = undefined;

    chart.legend.useDefaultMarker = true;
    let marker = chart.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    marker.width = 20;
    marker.height = 20;

    chart.legend.valueLabels.template.align = "left";
    chart.legend.valueLabels.template.textAlign = "start";

      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    let legendContainer = am4core.create("legenddiv", am4core.Container);
      if(legendContainer.logo) {
          legendContainer.logo.disabled = true;
      }
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart.legend.parent = legendContainer;

    return () => {
      chart.dispose();
    };
  }, []);

  return (
      <div className="testResultContainer d-flex flex-column">
          <p className="pretitle text-start text-md-center mb-0">Tus respuestas te definen como un inversor:</p>
          <div className="title text-start text-md-center">{resultTitle}</div>
          <p className="base-text bg-lt-grey p-3 p-sm-4 p-md-5 order-3 order-md-5">{resultText}</p>
          <p className="sugestion text- start text-md-center mt-3 mt-md-4 mt-xl-5 order-4 order-md-3"><strong>Portafolio sugerido para tu perfil</strong></p>
          <div className="chart-holder d-flex flex-column flex-md-row align-items-center order-5 order-md-4">
            <div id="chartdiv" className=""></div>
            <div id="legenddiv" className=""></div>
          </div>
    </div>
  );
}

export default PieChart;
