// Step 1: Import React
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Toast,
  ToastContainer,
  Spinner,
} from "react-bootstrap";
import { Layout } from "../components/Layout";
import { apiRequest } from "../apiRquest/apiRequest";
import VerticalSteps from "../components/vertical_steps/vertical_steps";
import PieChart from "../components/piechart/pie_chart";
import OptionBox from "../components/option_box/option_box";
import Button from "../components/buttons/button";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/bg_test.jpg";
import Loading from "../components/loading/loading";
import "./perfil-inversor.css";
import { SalWrapper } from "react-sal";
import NewsLetter from "../components/newsletter/newsletter";
const pageTitle = "Hacé el test y descubrí que tipo de inversor sos | Balanz";
const pageDesc =
  "¿Ya sabés que tipo de inversor sos? Descubrilo antes empezar a invertir. Conocé tu perfil de riesgo y los instrumentos financieros recomendados.";

// Step 2: Define your component
const PerfilInversor = () => {
  const [respuestaId, setRespuestaId] = useState(1);
  const [token, setToken] = useState("");
  const [question, setQuestion] = useState("");
  const [questionArray, setQuestionArray] = useState([]);
  const [questionNumber, setQuestionNumber] = useState(0);

  const [loading, setLoading] = useState(false);

  const [resultTitle, setResultTitle] = useState("");
  const [resultText, setResultText] = useState("");
  const [resultArray, setResultArray] = useState([]);

  const [hold, setHold] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const toggleShow = () => setShowToast(!showToast);

  // let testInversor = document.querySelector(".test-inversor");

  const handleSubmit = () => {
    //let stepCircles = document.querySelectorAll(".stepCircle");
    let activeBox = document.querySelector(".activeOptionBox");
    let activeStep = document.querySelector(".activeStep");
    let activeQuestion = document.querySelector(".activeStepQuestion");

    if (activeStep) {
      activeStep.classList.remove("activeStep");
      activeQuestion.classList.remove("activeStepQuestion");
    }

    /* if (stepCircles.length > 0) {
      stepCircles.forEach((element) => {
        element.style.backgroundColor = "white";
      });
    }*/

    if (activeBox) activeBox.classList.remove("activeOptionBox");

    if (respuestaId >= 1) {
      //setToken("");
      setHold(true);
      setQuestionNumber(questionNumber + 1);
    } else {
      toggleShow();
    }
  };

  /*
  useEffect(() => {
    apiRequest
      .post(`test-inversor-api/test-inversor-resultado.json`, {})
      .then(function (response) {
        setResultTitle(response.data.perfil);
        setResultArray(response.data.composicion);
        setResultText(response.data.respuesta);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    return () => {};
  }, []);
*/

  useEffect(() => {
    let testInversorDiv = document.querySelector(".test-inversor");

    const firstQuestion = () => {
      // console.log('questionNumber', questionNumber)
      setLoading(true);
      const colors = ["#ccedfa", "#99daf4", "#66c8ef", "#33b5e9", "#00a3e4"];
      apiRequest
        .get(
          //`test-inversor-api/test-inversor-pregunta${questionNumber + ""}.json`
          `/v1/inverter_test/questions`
        )
        .then(function (response) {
          // handle success
          setHold(false);
          setToken(response.data.token);
          setQuestion(response.data.testInversor.pregunta);
          setRespuestaId(0);
          let questionArray = response.data.testInversor.respuestas.map(
            (item, index) => {
              return {
                color: "white",
                text: item.respuesta,
                value: item.idrespuesta,
                alt: colors[index],
              };
            }
          );
          setQuestionArray(questionArray);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const nextQuestion = () => {
      const colors = ["#ccedfa", "#99daf4", "#66c8ef", "#33b5e9", "#00a3e4"];
      setLoading(true);
      apiRequest
        .post(
          //`test-inversor-api/test-inversor-pregunta${questionNumber + ""}.json`,
          `/v1/inverter_test/answer`,
          { token: token, idRespuesta: respuestaId }
        )
        .then(function (response) {
          // handle success
          setHold(false);
          setToken(response.data.token);
          if (response.data.testInversor?.numeropregunta) {
            setQuestion(response.data.testInversor.pregunta);
            setRespuestaId(0);
            let questionArray = response.data.testInversor.respuestas.map(
              (item, index) => {
                return {
                  color: "white",
                  text: item.respuesta,
                  value: item.idrespuesta,
                  alt: colors[index],
                };
              }
            );
            setQuestionArray(questionArray);
          } else {
            setResultTitle(response.data.perfil);
            setResultArray(response.data.composicion);
            setResultText(response.data.respuesta);
            setHold(false);
            setToken(null);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (hold) {
      if (!token && questionNumber === 1) firstQuestion();
      else nextQuestion();
      //testInversorDiv.scrollIntoView({ behavior: 'smooth', block: 'start'})

      let headerOffset = 50;
      const curScroll = window.scrollY - headerOffset;
      let elementPosition = testInversorDiv.getBoundingClientRect().top;

      //let offsetPosition = elementPosition - headerOffset;
      let offsetPosition = elementPosition + curScroll;
      //console.log('scrollto', offsetPosition)
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [hold, token, question, respuestaId, questionNumber]);

  let options = [
    {
      color: "white",
      alt: "yellow",
      text: "Hasta el 10%",
      value: 1,
    },
    {
      color: "white",
      alt: "blue",
      text: "Menos de 1 año",
      value: 2,
    },
    {
      color: "white",
      alt: "black",
      text: "Hasta el 10% ",
      value: 3,
    },
    {
      color: "white",
      alt: "green",
      text: "Hasta el 10%",
      value: 4,
    },
  ];

  const stepper = (
    <ul className="perfil-stepper">
      <li className={`dash-step ${questionNumber >= 1 ? "active" : ""}`}></li>
      <li className={`dash-step ${questionNumber >= 2 ? "active" : ""}`}></li>
      <li className={`dash-step ${questionNumber >= 3 ? "active" : ""}`}></li>
      <li className={`dash-step ${questionNumber >= 4 ? "active" : ""}`}></li>
      <li className={`dash-step ${questionNumber >= 5 ? "active" : ""}`}></li>
      <li className={`dash-step ${questionNumber >= 6 ? "active" : ""}`}></li>
      <li className={`dash-step ${questionNumber >= 7 ? "active" : ""}`}></li>
    </ul>
  );

  const text = "Test del <b>Inversor</b>";

  const page = (
    <>
      <ProductBanner
        bannerContainerClassName="bannerPerfil"
        bannerTrasparecy="bannerMask"
        productTitle={text}
        bannerImage={Banner}
        bannerImageClassName="cedearsBannerImage"
        textContainerClassName="textContainer"
        bannerText={false}
        bannerTextClassName="highlightsbold white"
        bannerImageMobile={Banner}
        mobile={false}
      />
      <SalWrapper>
        <section className="fullwidth test-inversor">
          <Container>
            {loading && (
              <Loading customClass="loading-test position-relative " />
            )}
            {!loading &&
              !token &&
              questionNumber > 1 &&
              resultArray.length === 0 && (
                <Row className="g-0 d-flex justify-content-center">
                  <Col xs={12} md={10}>
                    <div className="testStart">
                      <h2>Cargando...</h2>
                      <Spinner animation="border" variant="primary" size="xl" />
                    </div>
                  </Col>
                </Row>
              )}
            {!loading && !token && questionNumber === 0 && (
              <Row className="g-0 d-flex justify-content-center">
                <Col xs={12} md={10}>
                  <div className="testStart text-start text-sm-center">
                    <h2>
                      <strong
                        className="primary-color"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="300"
                      >
                        ¿Sabés qué tipo de inversor sos?
                      </strong>
                    </h2>
                    <p
                      className="base-text"
                      data-sal="fade"
                      data-sal-delay="100"
                      data-sal-duration="300"
                    >
                      Queremos ayudarte a definir cuál es la inversión que se
                      adapta mejor a tus expectativas, de acuerdo a tus
                      necesidades presentes y futuras.
                    </p>
                    <p className="base-text pt-3">
                      <strong
                        className="challenge"
                        data-sal="fade"
                        data-sal-delay="300"
                        data-sal-duration="300"
                      >
                        Realizá el test y conocé tu perfil.
                      </strong>
                    </p>
                    <div
                      className="submitArea text-start text-sm-center py-2"
                      data-sal="fade"
                      data-sal-delay="350"
                      data-sal-duration="300"
                    >
                      <Button
                        variant="secondary testStartButton"
                        onClick={handleSubmit}
                        text="Continuar"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {!loading &&
              token &&
              (questionNumber <= 2 || questionNumber >= 6) && (
                <Row className="g-0 d-flex justify-content-center">
                  <Col xs={12} data-qnum={questionNumber}>
                    {
                      <div className="questionPI base-text-2 pt-0 pt-sm-2 pt-md-3 pt-lg-4">
                        {question ? question : "Loading"}
                      </div>
                    }
                    <VerticalSteps
                      options={
                        questionArray.length > 0 ? questionArray : options
                      }
                      setRespuestaId={setRespuestaId}
                    />
                    <div className="submitArea fixbtn text-end text-sm-center py-3">
                      <Button
                        variant="secondary testStartButton"
                        onClick={handleSubmit}
                        text="Continuar"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={8} lg={6}>
                    <div className="stepper-holder">{stepper}</div>
                  </Col>
                </Row>
              )}
            {!loading &&
              token &&
              questionNumber >= 3 &&
              questionNumber <= 5 && (
                <Row className="g-0 d-flex justify-content-center">
                  <Col xs={12} data-qnum={questionNumber}>
                    {
                      <div className="questionPI base-text-2">
                        {question ? question : "Loading"}
                      </div>
                    }
                    <OptionBox
                      options={
                        questionArray.length > 0 ? questionArray : options
                      }
                      setRespuestaId={setRespuestaId}
                    />
                    <div className="submitArea fixbtn text-end text-sm-center py-sm-2 py-3">
                      <Button
                        variant="secondary testStartButton"
                        onClick={handleSubmit}
                        text="Continuar"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={8} lg={6}>
                    <div className="stepper-holder">{stepper}</div>
                  </Col>
                </Row>
              )}
            {!loading && !token && resultArray.length > 0 && (
              <Row className="g-0 d-flex justify-content-center">
                <Col xs={12}>
                  <PieChart
                    dataSet={resultArray}
                    resultText={resultText}
                    resultTitle={resultTitle}
                  />
                  <div className="submitArea text-start text-md-center py-2">
                    <Button
                      variant="secondary"
                      text="Empezá a invertir"
                      onClick={() => {
                        window.open("https://clientes.balanz.com/", "_blank");
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}

            <ToastContainer position="middle-end" className="fixedToast">
              <Toast
                show={showToast}
                onClose={toggleShow}
                bg="danger"
                delay={3000}
                autohide
              >
                <Toast.Header>
                  <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>
                  <span style={{ color: "white" }}>
                    Por favor seleccione una respuesta para continuar con el
                    formulario
                  </span>
                </Toast.Body>
              </Toast>
            </ToastContainer>
          </Container>
        </section>
      </SalWrapper>
      <section className="fullwidthbg cedearspack">
        <Container>
          <Row className="justify-content-between">
            <div className="section-content col-text order-2 order-md-1">
              <h2 className="titulo mb-2">
                <span class="block-quote">
                  ¿Por qué es importante conocer tu perfil de inversor?
                </span>
              </h2>
              <p>
                Conocer tu perfil de inversor es el primer paso sugerido para
                comenzar a invertir, ya que a partir de ahí podés elegir cuáles
                son las opciones de inversión que más se adaptan a tus
                necesidades.
              </p>
              <p>
                Es decir, cuando hablamos de perfil de inversor nos referimos a
                la relación entre los ingresos que esperamos percibir de
                nuestras inversiones y el riesgo que estamos dispuestos a
                asumir, durante el período o plazo que planificamos para nuestra
                cartera. Por eso, poder definir cuánto queremos invertir, por
                cuánto tiempo y cuánto estamos dispuestos a perder, es clave a
                la hora de elegir los activos.
              </p>
            </div>
          </Row>
        </Container>
      </section>
      <section className="fullwidth primeros-pasos pb-0">
        <Container>
          <Row className="pb-5 pt-3">
            <h3
              className="mt-2 pb-2 pb-md-3"
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-duration="500"
            >
              <span className="primary-color">
                Existen tres tipos de perfiles{" "}
              </span>
            </h3>
          </Row>
          <Row className="pb-5 paso-1 titles-test">
            <h2 data-sal="fade" data-sal-delay="200" data-sal-duration="200">
              Perfil de Inversor Conservador{" "}
            </h2>
            <p
              className="base-text"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="200"
            >
              Este perfil de inversor desea formar una cartera tradicional con
              instrumentos financieros que tengan bajo riesgo, para así mantener
              el valor del capital y no sufrir pérdidas. Este tipo de inversión
              genera ganancias apenas por encima de la inversión realizada,
              priorizando estabilidad en el valor de sus inversiones y esperando
              un rendimiento modesto. El inversor está dispuesto a perder poco o
              nada de dinero, esto puede ser por tener capital muy limitado,
              porque prefiere tener una disponibilidad inmediata de su dinero
              para afrontar pagos en el corto plazo, o por rasgos de su
              personalidad.
            </p>
          </Row>
        </Container>
        <div className="bg-lt-grey">
          <Container>
            <Row className="py-bigger paso-2">
              <h2 data-sal="fade" data-sal-delay="200" data-sal-duration="200">
                Perfil de Inversor Moderado
              </h2>
              <p
                className="base-text"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-duration="200"
              >
                Este perfil de inversor busca el crecimiento de su capital a lo
                largo del tiempo. Tiene una aversión al riesgo moderada, por lo
                tanto, está dispuesto a asumir variaciones del valor de su
                inversión a cambio de incrementar su capital en el mediano
                plazo. Se mantienen en un nivel balanceado. La cartera de estos
                inversores suele estar compuesta en su mayoría por títulos
                de renta fija, y un pequeño porcentaje del capital puede estar
                invertido en instrumentos de renta variable, como las acciones.
              </p>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className="py-bigger paso-3">
            <h2 data-sal="fade" data-sal-delay="200" data-sal-duration="200">
              Perfil de Inversor Agresivo
            </h2>
            <p
              className="base-text"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="200"
            >
              Este perfil de inversor persigue altos niveles de rentabilidad en
              sus inversiones al posicionarse en activos con mayor variabilidad
              de precio, en general, de largo plazo. Invertir en estos activos
              implica un mayor nivel de riesgo. Las carteras de este perfil
              tienen una mayor participación en instrumentos de renta variable y
              de productos derivados , como futuros y opciones.
            </p>
          </Row>
        </Container>
        <section className="fullwidth suscribe">
              <Container>
                  <Row className="d-flex justify-content-center">
                      <Col xs="12" md="11">
                          <NewsLetter
                              Tittle="Enterate de los eventos más relevantes del mercado"
                              Text="Suscribite al newsletter y <br />
                              recibí nuestro Daily."
                              ClassNameDisplay="section-content"
                              ClassNameInput="custom-email-input"
                              ButtonStyle="secondary"
                              ButtonText="Enviar"
                              IDCampaignAction={1059}
                          />
                      </Col>
                  </Row>
              </Container>
          </section>
      </section>
    </>
  );

  return (
    <main>
      <Layout
        title={pageTitle}
        description={pageDesc}
        childrem={page}
        category={"test_del_inversor"}
      ></Layout>
    </main>
  );
};

// Step 3: Export your component
export default PerfilInversor;
